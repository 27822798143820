:root {
    --arancio-primario: #F14626;
    --arancio-secondario: #FAB57F;
    --verde-sostenibilita: #5eb134;
    --testo-primario: #333333;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    
}

body {
    background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/cielo.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
    min-height: 100vh;
}

.bg_cielo {
    background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/cielo.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100vh;
    background-position: top center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}


.nonvedente {
	position: absolute;
	font-size: 0;
	z-index: -1000000;
}

/* --- tasto principale --- */
.tasto_principale {
    display: inline-flex;
    cursor: pointer;
    min-width: 160px;
    min-height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(246,132,41,1) 0%, rgba(242,39,59,1) 100%);
    color: #FFF;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.tasto_principale::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    z-index: 0;
    opacity: 0.1;
}

.tasto_principale::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(-45deg, rgba(246, 132, 41, 1) 25%, transparent 25%, transparent 50%, rgba(246, 132, 41, 1) 50%, rgba(246, 132, 41, 1) 75%, transparent 75%, transparent);
    background-size: 10px 10px;
    z-index: 0;
    mix-blend-mode: lighten;
    opacity: 0.2;
}

.tasto_principale:active {
    background: linear-gradient(0deg, rgba(250,181,127,1) 0%, rgba(250,181,127,1) 100%);
}

.tasto_principale_ombra {
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
}

.tasto_principale.tasto_principale_outline {
    border:2px solid var(--arancio-primario);
    color: var(--arancio-primario);
    background: #FFF;
    transition: all 200ms linear;
}

.tasto_principale.tasto_principale_outline:active {
    border:2px solid var(--arancio-secondario);
    color: var(--arancio-secondario);
}

.tasto_principale.tasto_principale_outline::before {content: none;}
.tasto_principale.tasto_principale_outline::after {content: none;}

/* --- tasto secondario --- */
.tasto_secondario {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    color: var(--arancio-primario);
    text-decoration: underline;
    transition: color 200ms linear;
}

.tasto_secondario:active {
    color: var(--arancio-secondario);
}

/* --- pop default --- */
.pop_default {
    width: 100%;
    max-width: 480px;
    background: #FFF;
    border-radius: 40px;
    border:4px solid #eee;
    padding: 25px 0;
    overflow-y: auto;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
} 

.contenuto_principare_pop {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.pop_default_titolo {
    display: block;
    width: 100%;
    color: var(--arancio-primario);
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 25px;
}

.pop_default_descrizione {
    display: block;
    width: 100%;
    color: var(--testo-primario);
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    padding: 0 25px;
}

.pop_default_foto_descrizione {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 25px;
}

.azioni_pop {
    display: block;
    width: 100%;
    text-align: center;
    border-top: 1px solid #eee;
    padding: 20px 25px 0 25px;
    margin-top: 20px;
}

.azioni_pop .tasto_secondario {
    margin-top: 20px;
}

/* --- paginatore slider --- */
.swiper-pagination .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    background: #eee;
    opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--arancio-primario);
}

/* ---- sostenibilita ---- */
.colore_sostenibilita {
    color: var(--verde-sostenibilita);
} 

.contatore_punti_sostenibilita {
    float: left;
    width: 100%;
    padding: 0 25px;
}

.tuoi_punti_sostenibilita {
    float: left;
    width: 100%;
    height: 40px;
    background-color: var(--verde-sostenibilita);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    line-height: 28px;
    font-weight: 700;
    color: #FFF;
}

/*
.community_punti_sostenibilita {
    float: right;
    width: 50%;
    height: 40px;
    background-color: var(--verde-sostenibilita);
    border-radius: 0 40px 40px 0;
    border-left: 1px solid #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    color: #FFF;
}
*/
.contatore_punti_sostenibilita_label {
    float: left;
    width: 100%;
    padding: 0 25px;
    margin-top: 5px;
}

.punti_sostenibilita_label {
    float: left;
    width: 100%;
    color: var(--verde-sostenibilita);
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

/* ----------- video full screen ------------ */
.video_full_screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.video_full_screen video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/* --------------------------------------------- GIOCHI ----------------------------------- */
.gioco_tutorial {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 25px;
    z-index: 100;
    overflow-y: auto;
    padding-top: calc(25px + env(safe-area-inset-top));
    text-align: center;
} 

/* --- pillola gioco tutorial --- */
.popup_gioco_pillola {
    display: block;
    width: 100%;
    padding: 0 25px 15px 25px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
}

.popup_gioco_pillola_brand {
    display: block;
    width: 100%;
    text-align: center;
}

.popup_gioco_pillola_brand img {
    display: inline-block;
    max-width: 200px;
    max-height: 120px;
}

.popup_gioco_pillola_titolo {
    display: block;
    width: 100%;
    padding: 0 25px;
    text-align: center;
    color: var(--testo-primario);
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    margin: 15px 0 5px 0;
}

.popup_gioco_pillola_descrizione {
    display: block;
    width: 100%;
    padding: 0 25px;
    text-align: center;
    color: var(--testo-primario);
    font-size: 16px;
    line-height: 22px;
}

/* --- pop gioco finito --- */
.pop_gioco_finito {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    transform: translateY(100vh);
    transition: transform 0ms 400ms linear;
}

.pop_gioco_finito_visibile.pop_gioco_finito {
    transform: translateY(0);
    transition: transform 0ms 0ms linear;
}

.pop_gioco_finito_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.56);
    z-index: 0;
    opacity: 0;
    backdrop-filter: blur(2px);
    animation-name: sfocatura_via;
    animation-timing-function: linear;
    animation-duration: 300ms;
}

.pop_fine_gioco_immagine {
    width: 100%;
    height: auto;
    position: relative;
    bottom: 0;
    flex-grow: 1;
    overflow: hidden;
}

.pop_fine_gioco_immagine img {
    position: absolute;
    object-fit: cover;
    object-position:top center;
    height: 100%;
    width: 100%;
    bottom: 0;
}

.pop_gioco_finito .pop_default {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 80px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    z-index: 5;
    opacity: 0;
    animation-name: nascondi_pop_esci;
    animation-timing-function: ease-in;
    animation-duration: 300ms;
}

.pop_gioco_finito .pop_default .contenuto_principare_pop {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.pop_gioco_finito_visibile .pop_gioco_finito_bg {
    animation-name: sfocatura;
    animation-timing-function: linear;
    animation-duration: 300ms;
    animation-fill-mode: both;
}

.pop_gioco_finito_visibile .pop_default {
    animation-name: mostra_pop_esci;
    animation-timing-function: linear;
    animation-duration: 300ms;
    animation-fill-mode: both;
}

@keyframes mostra_pop_esci {
    from {opacity: 0; transform: translateY(-100px);}
    to {opacity: 1; transform: translateY(0);}
}

@keyframes nascondi_pop_esci {
    from {opacity: 1; transform: translateY(0);}
    to {opacity: 0; transform: translateY(100px);}
}

@keyframes sfocatura {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes sfocatura_via {
    from {opacity: 1;}
    to {opacity: 0;}
}

/* ------- pillola post quiz -------- */
.pillola_quiz a {
    color: var(--testo-primario);
}

.pillola_quiz a:active {
    color: var(--arancio-primario);
}

/* ------------------------------- EDITORIALE ------------------------------------------- */
.contenuto_editoriale_descrizione a {
    color: var(--arancio-primario);
    transition: color 200ms linear;
}

.contenuto_editoriale_descrizione a:active {
    color: var(--arancio-secondario);
}